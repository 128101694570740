<template>
  <div>
    <div class="view pa24 mr mb20">
      <span style="color: #8d97ad; font-size: 14px"
        >发布流程：1.选择商品分类 ------ 2.输入商品基本信息 ------
        3.输入商品详情 ------ 4.发布商品</span
      >
    </div>

    <el-form
      ref="form" :model="form" :rules="rules" label-width="auto" class="view pa24" label-position="left"
    >
      <p class="cA2 fwbold fs8 mb20">1.选择商品类型</p>
      <el-form-item prop="goodstypeId" label="商品分类">
        <el-select v-model="form.goodstypeId" placeholder="请选择商品类型">
          <template v-for="(item, index) in goodsType">
            <el-option
              :label="item.name"
              :value="item.goodstypeId"
              :key="index"
            />
          </template>
        </el-select>
        <el-button type="primary" class="ma ml10" @click="open"
          >添加分类</el-button
        >
      </el-form-item>
      <hr
        style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />
      <p class="cA2 fwbold fs8 mb20">2.输入商品基本信息</p>
      <el-form-item prop="goodsName" label="商品标题">
        <el-input
          v-model="form.goodsName"
          style="width: 350px"
          placeholder="请选择商品名称"
          maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item prop="stockNum" label="商品库存">
        <el-input
          v-model="form.stockNum"
          oninput="value=value.replace(/[^\d]/g,'')"
          type="number"
          style="width: 350px"
          placeholder="请选择商品库存"
          show-word-limit
          maxlength="10"
        ></el-input>
      </el-form-item>

      <el-form-item prop="goodSpecModelList" label="商品规格">
        <div
          style="
            border: 1px solid #e4e4e4;
            width: 550px;
            border-radius: 5px;
            padding: 10px 20px;
          "
          ref="addEl"
        >
          <template
            v-for="(item, index) in form.goodSpecRequestList ||
            form.goodSpecModelList"
          >
            <div :key="index" style="margin-top: 10px">

              <el-input
                type="text"
                placeholder="请输入商品类型名称"
                v-model="item.specName"
                style="width: 380px"
              /><span class="hover_pointer"
                @click="deleteName(index)"
                style="color: #51cdcb; margin-left: 5px"
                >删除</span>
              <span style="margin-left: 5px;">
               <el-switch
                       v-model="item.requiredSelect"
                       active-text="必选"
                       inactive-text="">
                </el-switch>
              </span>
              <template
                v-for="(attr, index1) in item.list || item.goodSpecModelList">
                <div :key="index1" style="margin-top: 5px">
                  <el-input
                    type="text"
                    placeholder="请输入商品规格"
                    v-model="attr.specAttribute"
                    style="width: 180px"
                    @blur="specAttribute =  attr.specAttribute"
                  /><el-input
                    v-model="attr.price"
                    type="number"
                    placeholder="请输入价格（单位：元）"
                    style="width: 200px"
                    @change="() => {
                      attr.price =  attr.price < 0.01 ? '' : (attr.price*1).toFixed(2)
                    }"
                  />
                  <span class="hover_pointer"
                    @click="deleteAttribute(index, index1)"
                    style="color: #51cdcb; margin-left: 5px; margin-right: 5px"
                    >删除</span
                  ><span class="hover_pointer" @click="addAttribute(index)" style="color: #51cdcb"
                    >添加属性</span
                  >
                </div>
              </template>
            </div>
          </template>
          <span class="hover_pointer" @click="addSpec()" style="color: #51cdcb; margin-top: 10px">
            添加规格
          </span>
        </div>
      </el-form-item>

      <el-form-item prop="goodPhoto" label="商品图片">
        <p class="ma mb10" style="color: rgb(150, 150, 150)">
          上传图片的最佳尺寸：800像素*800像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过4M，商品图片一共可以上传5张，默认第一张为主图封面
        </p>
        <div style="display: flex">
          <el-upload
            :action="uploadUrl"
            :class="{
              disabled: uploadDisabled,
            }"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="goodsUpload"
            :file-list="form.goodPhoto"
            :limit="5"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog
            :visible.sync="dialogVisible"
            append-to-body
            :modal-append-to-body="false"
          >
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </el-form-item>
      <hr
        style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />
      <el-form-item prop="coverImg"  label="分享图片">
        <p class="ma mb10" style="color: rgb(150, 150, 150)">
          上传图片及支持png/jpg 。显示图片长宽比为5:4
        </p>
        <div>
          <el-upload
            :action="uploadUrl"
            :class="{
              disabled: uploadDisabled2,
            }"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove2"
            :on-success="shareUpload"
            :file-list="form.coverImg"
            :limit="1"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog
            :visible.sync="dialogVisible"
            append-to-body
            :modal-append-to-body="false"
          >
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </el-form-item>
      <hr
        style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />
      <el-form-item label="是否参加分销" label-width="130px">
        <el-radio-group v-model="form.isDistribution">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="是否展示分销语" label-width="130px" v-if="form.isDistribution">
        <el-radio-group v-model="form.isHiddenTitle">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="是否发布到产品中心" label-width="150px">
        <el-radio-group v-model="form.isPublic">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <p class="cA2 fwbold fs8 mb20">3.支付方式</p>
      <el-form-item label="支付方式">
        <el-radio-group v-model="form.ispayment">
          <el-radio :label="1">线上支付</el-radio>
          <el-radio :label="0">其他</el-radio>
        </el-radio-group>
      </el-form-item>

      <p class="cA2 fwbold fs8 mb20">4.输入商品详情</p>
      <el-form-item label-width="0px">
        <p class="ma mb10">商品详情</p>
        <!-- //富文本 -->
        <quill-editor
          style="height: 100%"
          v-model="form.goodsDetails"
          ref="myQuillEditor"
          :options="editorOption"
          @ready="onEditorReady($event)"
        />
      </el-form-item>

      <el-form-item label-width="0px">
        <el-button
          type="primary"
          size="medium"
          @click="addGood"
          >发布</el-button
        >

        <el-button size="medium" @click="isShow = true">预览</el-button>
      </el-form-item>
      <prevGoods :isShow="isShow"
                :prod_imgs="form.goodPhoto"
                :prod-size="form.goodSpecRequestList || form.goodSpecModelList"
                :edit_html="form.goodsDetails"
                @close="prodClose"
              ></prevGoods>
    </el-form>
  </div>
</template>

<script>
// @ts-ignore
import {
  getGoodsTypeInfo,
  addGoodsType,
  getGoodsInfo,
  addGoods,
} from "@/api/coupon.js";
import prevGoods from './components/prevGoods'
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写商品详情'

export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      console.log(this.form)
      if (this.form.goodSpecModelList[0] ||  this.form.goodSpecRequestList[0]  ) {
        callback();
      } else {
        callback(new Error("请输入商品属性"));
      }
    };
    var valiGoodPhoto = (rule, value, callback) => { // 图片验证
      if (this.form.goodPhoto.length == 0) {
        callback();
      } else {
        callback();
      }
    };
    var valiCoverImg = (rule, value, callback) => { // 图片验证
      if (this.form.coverImg.length == 0) {
        callback();
      } else {
        callback();
      }
    };
    return {
      editorOption: quillConfig,
      isShow: false,
      form: {
        goodsName: "", //商品名称
        stockNum: "", //库存
        goodstypeId: "", //类型ID
        goodsDetails: "", //商品详情
        ispayment: 1, //支付方式
        isDistribution: 0, //分销售
        isPublic: false, //上架
        goodPhoto: [], //商品图片
        coverImg: [],
        isHiddenTitle: 0,
        //商品规格
        specAttribute:'',
        goodSpecRequestList: [
          {
            specName: "", //规格名称
            list: [
              {
                specAttribute: "", //规格属性（价格）
                specNum: "1", //规格数量
                price: '', //价格除100
                requiredSelect:false
              },
            ],
          },
        ],
        goodSpecModelList:[],
      },
      rules: {
        goodstypeId: [{ required: true, message: "请选择分类！" }],
        goodsName: [
          {
            required: true,
            message: "请输入产品标题！",
            trigger: "change",
          },
        ],
        stockNum: [{ required: true, message: "请输入库存", trigger: "change"}
        ],
        goodSpecModelList: [{ required: true, validator: validatePass2 , trigger: "change" }],
        goodsDetails: [
          { required: true, message: "请输入描述", trigger: "change" },
        ],
        goodPhoto: [{ required: true, validator: valiGoodPhoto, trigger: "change"  }],
        coverImg: [{ required: true, validator: valiCoverImg , trigger: "change" }],
      },
      goodsType: [], //分类渲染
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      dialogImageUrl: "",
      dialogVisible: false,
      indexs: "",
    };

  },
  components: {
    prevGoods,
    quillEditor
  },
  computed: {
    uploadDisabled() {
      return this.form.goodPhoto.length >= 5;
    },
    uploadDisabled2() {
      return this.form.coverImg && this.form.coverImg.length >= 1;
    },
  },
  async created() {
    this.goodsId = this.$route.query.id;
    if (this.$route.query.id) {
      const result = await getGoodsInfo({ goodsId: this.$route.query.id });

      let results = JSON.parse(result.data.pageInfo).list[0];
       console.log(results)
      if (results.goodPhoto ) {
        results.goodPhoto = results.goodPhoto.split(",").map((item, index) => {
          return {
            url: item,
            uid: index,
          };
        });
      }
      if (results.coverImg && results.coverImg.length !== 2 && results.coverImg.length !== 0) {
        results.coverImg = [{ url: results.coverImg, uid: 1 }];
      } else {
        results.coverImg = [];
      }
      this.form = results;
      this.form.goodSpecModelList.forEach((val, k) => {
        for (var i in val.goodSpecModelList) {
          val.goodSpecModelList[i].price = (val.goodSpecModelList[i].price / 100).toFixed(2);
          this.specAttribute =  val.goodSpecModelList[i].specAttribute
          console.log(this.specAttribute)
        }
      });
    }
    const datas = await getGoodsTypeInfo({});
    this.goodsType = datas.data;
  },

  methods: {
    async getGoodsInfo() {},
    delImg(index) {
      this.form.goodPhoto.splice(index, 1);
    },

    open1() {
      this.$alert(
        `
      <div style="color:red">
          <div>
      </div>`,
        {
          dangerouslyUseHTMLString: true,
          // showClose: false,
          showonConfirmButton: true,
          distinguishCancelAndClose: false,
        }
      );
    },

    addSpec: function () {
      if (!this.$route.query.id) {
        this.form.goodSpecRequestList.push({
          specName: "",
          list: [
            {
              specAttribute: "",
              specNum: 1,
              price: 0,
            },
          ],
        });
      } else {
        this.form.goodSpecModelList.push({
          specName: "",
          goodSpecModelList: [
            {
              specAttribute: "",
              specNum: 1,
              price: 0,
            },
          ],
        });
      }
    },

    deleteAttribute: function (index, index1) {
      if (!this.$route.query.id) {
        this.form.goodSpecRequestList[index].list.splice(index1, 1);
      } else {
        this.form.goodSpecModelList[index].goodSpecModelList.splice(index1, 1);
      }
    },

    deleteName: function (index) {
      if (!this.$route.query.id) {
        this.form.goodSpecRequestList.splice(index, 1);
      } else {
        this.form.goodSpecModelList.splice(index, 1);
      }
    },

    addAttribute: function (index) {
      if (!this.$route.query.id) {
        this.form.goodSpecRequestList[index].list.push({
          specAttribute: "",
          specNum: 1,
          price: 0,
        });
      } else {
        this.form.goodSpecModelList[index].goodSpecModelList.push({
          specAttribute: "",
          specNum: 1,
          price: 0,
        });
      }
    },

    onSubmit() {
      console.log("submit!");
    },

    handleRemove(file, fileList) {
      this.form.goodPhoto = fileList;
    },
    handleRemove2(file, fileList) {
      this.form.coverImg = fileList;
    },
    //新增商品
    addGood() {
      if(this.form.goodPhoto.length == 0){
        return this.$message({
          message: "商品图片未上传",
          type: "warning",
          center: true,
        });
      }
      if(this.form.coverImg.length == 0){
        return this.$message({
          message: "商品分享图片未上传",
          type: "warning",
          center: true,
        });
      }
      let err = ''
      this.$refs.form.validate(async (valid) => {
        if (valid) {
        if(this.form.goodSpecRequestList){
          this.form.goodSpecRequestList.forEach((val, k) => {
            if(!val.specName){
              err = true
              return
            }
            for (var i in val.list) {
              if(!val.list[i].price || !val.list[i].specAttribute){
                err = true
                break
              }
              val.list[i].price = Number(val.list[i].price.replace(".", ""));
            }
          });
        }else{
            this.form.goodSpecModelList.forEach((val, k) => {
              for (var i in val.goodSpecModelList) {
                if(!val.goodSpecModelList[i].price || !val.goodSpecModelList[i].specAttribute){
                  err = true
                  break
                }
                val.goodSpecModelList[i].price = Number(val.goodSpecModelList[i].price.replace(".", ""));
              }
            });
        }
        if(err){
          this.$message.error('请完善商品规格')
          return
        }
        let arr = this.form.goodPhoto.map((item) => item.url);

        let data = {
          goodsName: this.form.goodsName, //商品名称
          stockNum: this.form.stockNum, //库存
          goodstypeId: this.form.goodstypeId, //类型ID
          goodsDetails: this.form.goodsDetails, //商品详情
          ispayment: this.form.ispayment, //支付方式
          isDistribution: this.form.isDistribution, //分销售
          isPublic: this.form.isPublic, //上架
          goodPhoto: arr.join(","), //商品图片
          goodsId: this.goodsId,
          isHiddenTitle:this.form.isHiddenTitle, //是否展示分销语
          coverImg:
            this.form.coverImg && this.form.coverImg.length != 0
              ? this.form.coverImg[0].url
              : [],
        };
        if (this.$route.query.id) {
          data.goodSpecRequestList = this.form.goodSpecModelList.map((row) => {
            return {
              specName: row.specName,
              specId: row.specId,
              requiredSelect : row.requiredSelect,
              list: row.goodSpecModelList.map((item) => {
                return {
                  specAttribute: item.specAttribute,
                  specNum: item.specNum,
                  price: item.price,
                  specId: item.specId,
                };
              }),
            };
          });
        }
        
        const loading = this.$loading({
          lock: true,
          text: '发布中，请稍等...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        if (this.$route.query.id) {
          addGoods(data)
          .then(() => {
            loading.close();
            this.$message({
              message: "操作成功",
              type: "success",
              center: true,
            });
            this.$router.go(-1);
          })
          .catch(err=>{
            loading.close();
            this.form.goodSpecRequestList.forEach((val, k) => {
              if(!val.specName){
                err = true
                return
              }
              for (var i in val.list) {
                if(!val.list[i].price || !val.list[i].specAttribute){
                  err = true
                  break
                }
                val.list[i].price = (val.list[i].price / 100).toFixed(2);
              }
            });
            this.$message({
              message: err.message,
              type: "success",
              center: true,
            });
          });
        } else {
          let val = JSON.parse(JSON.stringify(this.form))
          val.goodPhoto = arr.join(",")
          val.coverImg = this.form.coverImg[0].url
          addGoods(val).then(() => {
            loading.close();
            this.$message({
              message: "操作成功",
              type: "success",
              center: true,
            });
            this.$router.go(-1);
          })
          .catch(err=>{
            loading.close();
            this.form.goodSpecRequestList.forEach((val, k) => {
              if(!val.specName){
                err = true
                return
              }
              for (var i in val.list) {
                if(!val.list[i].price || !val.list[i].specAttribute){
                  err = true
                  break
                }
                val.list[i].price = (val.list[i].price / 100).toFixed(2);
              }
            });
            this.$message({
              message: err.message || '发布失败，请稍后再试',
              type: "warning",
              center: true,
            });
          });
        }
      } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    delAtt(index) {
      this.indexs = index;
    },

    //分享图片
    shareUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://lan8-e-business.oss-cn-hangzhou.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.form.coverImg = arr;
    },

    // //商品图片
    goodsUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://lan8-e-business.oss-cn-hangzhou.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.form.goodPhoto = arr;
      console.log(this.form.goodPhoto)
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //新增分类弹窗
    open(row) {
      this.$prompt("请输入商品类型名称", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then( async ({ value }) => {
          await addGoodsType({
            name: value,
            sort: 0,
            isDel: 0,
          });
          const datas = await getGoodsTypeInfo({}); //获取分类数据
          this.goodsType = datas.data;
          this.$message({
            type: "success",
            message: "添加成功",
            center: true,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
            center: true,
          });
        });
    },
    prodClose() {
      this.isShow = false;
    },
    onEditorReady(quill) {
      // console.log('editor ready!', quill)
      let btnImg = document.querySelector('.ql-image')
      if (btnImg) {
        console.log(btnImg)
        btnImg.append('上传图片')
      }
    }
  },
};
</script>
<style lang="scss" scope>
.disabled .el-upload--picture-card {
  display: none;
}
.ql-image {
  width: auto !important;
}
</style>
